import { getStreamerIdToken } from 'COMPONENTS/Authentication/authentication';
import { STREAMER_CONST } from 'CONSTANTS/apiConstants';
import { ROUTES } from 'CONSTANTS/appConstants';
import { useEffect, useState } from 'react';
import languages from 'RESOURCES/languages/index';
import { injectCallbackToGetAccessToken } from 'SERVICES/axiosInit';
import { addI18nResources } from 'SERVICES/i18n'; // also initializing i18n
import logger from 'SERVICES/logger';
const useAppInitializer = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  const path = window.location.pathname;

  useEffect(() => {
    logger.info(`Package Version: ${process.env.REACT_APP_VERSION}`);
    logger.info(`Env : ${process.env.REACT_APP_ENV}`);
  }, []);

  const initializeStreamer = () => {
    injectCallbackToGetAccessToken(getStreamerIdToken as any);
    addI18nResources(STREAMER_CONST, languages);
  };

  useEffect(() => {
    setIsInitialized(true);
    if (path.startsWith(ROUTES.STREAMER)) {
      initializeStreamer();
    }
  }, []);

  return { isInitialized };
};
export default useAppInitializer;
