import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from 'COMPONENTS/Authentication/authConfig';
import { setMsalInstance } from 'COMPONENTS/Authentication/authentication';
import Home from 'COMPONENTS/Home/Home';
import { ROUTES } from 'CONSTANTS/appConstants';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const StreamerRouter = () => {
  const msalInstance = new PublicClientApplication(msalConfig as Configuration);
  setMsalInstance(msalInstance);
  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Routes>
          <Route
            path={`${ROUTES.STREAMER}/join/:meetingId`}
            element={<Home />}
            key={`Route-${ROUTES.STREAMER}/join/:meetingId`}
          />
        </Routes>
      </BrowserRouter>
    </MsalProvider>
  );
};

export default StreamerRouter;
