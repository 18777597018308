export const ROUTES = Object.freeze({
  STREAMER: '/streamer',
});

export interface ICallBackType {
  (): string;
}

export const VIDEO_GALLERY = {
  connected: 'Connected',
  stateChanged: 'stateChanged',
  screenSharing: 'ScreenSharing',
  videoStreamsUpdated: 'videoStreamsUpdated',
  isAvailableChanged: 'isAvailableChanged',
  remoteParticipantsUpdated: 'remoteParticipantsUpdated',
  displayNameChanged: 'displayNameChanged',
  isSpeakingChanged: 'isSpeakingChanged',
  isMutedChanged: 'isMutedChanged',
};
export interface IParticipantVideo {
  userId: string;
  displayName?: string;
  isMuted: boolean;
  isSpeaking: boolean;
  state?: string;
  isScreenSharingOn?: boolean;
  videoStream?: any;
  screenShareStream?: any;
}
export enum STREAM_TYPE {
  screenSharing = 'ScreenSharing',
}
export const PARTICIPANT_EVENT_CHANGE = {
  nameChanged: 'nameChanged',
  muteChanged: 'muteChanged',
  speakingChanged: 'speakingChanged',
  stateChanged: 'stateChanged',
  screenShareChanged: 'screenShareChanged',
  videoChanged: 'videoChanged',
};

export enum PARTICIPANT_EVENT_NAME {
  displayNameChanged = 'displayNameChanged',
  isAvailableChanged = 'isAvailableChanged',
  isMutedChanged = 'isMutedChanged',
  isSpeakingChanged = 'isSpeakingChanged',
  remoteParticipantsUpdated = 'remoteParticipantsUpdated',
  stateChanged = 'stateChanged',
  videoStreamsUpdated = 'videoStreamsUpdated',
}

export enum AUDIO_ELEMENT_CONSTANTS {
  AUDIO_STREAM_NAME = 'streamName',
  AUDIO = 'audio',
  BODY_SELECTOR = 'body',
  CHILD_LIST = 'childList',
}

export enum CALL_EVENTS {
  CALL_UPDATED = 'callsUpdated',
  STATE_CHANGED = 'stateChanged',
}

export enum CALL_STATE {
  none = 'None',
  connected = 'Connected',
  disconnected = 'Disconnected',
  other = 'Other',
}
