// import React from 'react';
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import { DEFAULT_COMPONENT_ICONS } from '@azure/communication-react';
import { registerIcons } from '@fluentui/react';
import useAppInitializer from 'HOOKS/useAppInitializer';
import ThemeProvider from 'RESOURCES/theme/ThemeProvider';
import StreamerRouter from 'ROUTES/StreamerRouter';

const App = () => {
  const { isInitialized } = useAppInitializer();
  if (!isInitialized) return null;
  registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

  // Cleaning cache problem AKMSTEAMS-539
  // Most important is that teams right panel uses localstorage
  // While clearing localstorage we cannot use localstorage.clear()
  // As all the interface are sharing same code
  const allKeys = Object.keys(localStorage) || [];
  allKeys.forEach((element) => {
    localStorage.removeItem(element);
  });

  return (
    <ThemeProvider>
      <StreamerRouter />
    </ThemeProvider>
  );
};

export default App;
