import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Flex, FlexItem, Loader, Text } from '@fluentui/react-northstar';
import { getStreamerMeetingDetails } from 'API/api';
import { loginRequest } from 'COMPONENTS/Authentication/authConfig';
import { teamMeetingActions } from 'COMPONENTS/Home/teamMeetingSlice';
import VideoGallery from 'COMPONENTS/VideoGallery/VideoGallery';
import { ERROR, STREAMER_CONST } from 'CONSTANTS/apiConstants';
import { CALL_EVENTS, CALL_STATE } from 'CONSTANTS/appConstants';
import useActions from 'HOOKS/useActions';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ACSCallClient from 'SERVICES/ACSCallClient';
import { getAudioElements } from 'SERVICES/audioElementUtils';
import logger from 'SERVICES/logger';
import { appSelector } from 'STORE/appSlice';
import Default from './Default';

const Home = () => {
  const teamMeeting = 'TeamMeeting';
  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { t } = useTranslation(STREAMER_CONST, { keyPrefix: 'STREAMER' });
  const [teamsCall, setTeamsCall] = useState<ACSCallClient | undefined>(undefined);
  const [apiError, setApiError] = useState<any>(undefined);
  const [loadingMessage, setLoadingMessage] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [callEnded, setCallEnded] = useState(undefined);

  const { meetingId } = useParams();
  const errorTimestamp = useSelector(appSelector.errorTimestamp);

  const { setTeamsCallClientObject, callAdded, setCallState, setIncomingAudioElement } =
    useActions(teamMeetingActions);

  const setUpTeamsCall = async (teamMeetingUrl: string) => {
    setLoadingMessage(t('JOINING_MEETING_MESSAGE'));
    const callClientObj = new ACSCallClient();
    await callClientObj.setupCallTeamsCall();
    setTeamsCallClientObject(callClientObj);
    setTeamsCall(callClientObj);
    callClientObj.subscribe(CALL_EVENTS.CALL_UPDATED, (addedCall: any) => {
      try {
        callAdded(addedCall);
        setCallState(addedCall?.state);
        addedCall?.on(CALL_EVENTS.STATE_CHANGED, async () => {
          setCallState(addedCall.state);
          if (addedCall.state === CALL_STATE.connected) {
            setLoadingMessage(t('SETTING_UP_MESSAGE'));
            setIsLoading(false);
          }
          if (addedCall.callEndReason?.subCode === 4097) {
            logger.debug('Meeting ended.');
            setCallEnded(addedCall.callEndReason?.subCode);
          }
        });
      } catch (error) {
        logger.error('Error In Connecting to teams call', error);
        throw error;
      }
    });
    callClientObj.joinMeeting({ meetingLink: teamMeetingUrl });
    const teamMeetingAudioTag = await getAudioElements(teamMeeting);
    logger.debug([teamMeetingAudioTag], 'Team Meeting Audio Tag');
    setIncomingAudioElement(teamMeetingAudioTag);
  };

  useEffect(() => {
    if (accounts && isAuthenticated) {
      //Using an IIFE as we are not allowed to have async useEffect
      (async function setUpCalls() {
        const [account] = accounts;
        try {
          if (account && account.idTokenClaims && account.idTokenClaims.sub) {
            logger.debug('Name:', account.idTokenClaims.name);
            logger.debug('AdB2cId::', account.idTokenClaims.sub);
            if (account.idTokenClaims.sub && meetingId) {
              setIsLoading(true);
              // fetchMeeting data
              setLoadingMessage(t('FETCH_MEETING_MESSAGE'));
              const responseFromMeetingAPI = await getStreamerMeetingDetails(meetingId);

              // join teams call
              await setUpTeamsCall(responseFromMeetingAPI.joinUrl);
            }
          }
        } catch (error: any) {
          logger.error('ERROR:', error);
          setApiError(error.code === ERROR.MEETING_NOT_ASSIGNED ? error?.message : t('API_ERROR'));
        }
      })();
    }
  }, [accounts, isAuthenticated]);

  useEffect(() => {
    const cleanupStreamerHome = async () => {
      // Disconnect teams calls
      logger.debug('Hanging up teams call');
      await teamsCall?.hangupCall();
      logger.debug('Cleaning up teams call');
      await teamsCall?.disposeCall();

      setTeamsCall(undefined);
    };
    if (callEnded === 4097) {
      cleanupStreamerHome();
    }
  }, [callEnded]);

  // This one is for overall application error handler
  useEffect(() => {
    if (errorTimestamp) {
      setApiError(t('API_ERROR'));
    }
  }, [errorTimestamp]);
  useEffect(() => {
    const cleanupStreamerHome = async () => {
      // Disconnect teams calls
      logger.debug('Hanging up teams call');
      await teamsCall?.hangupCall();
      logger.debug('Cleaning up teams call');
      await teamsCall?.disposeCall();

      setTeamsCall(undefined);
    };
    cleanupStreamerHome();
  }, []);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={() => <Default />}
    >
      {apiError && (
        <Flex column fill vAlign="center" hAlign="center">
          <FlexItem>
            <Text weight="bold"> {apiError}</Text>
          </FlexItem>
        </Flex>
      )}
      {isLoading && !apiError && <Loader label={loadingMessage} />}
      {!isLoading && teamsCall && <VideoGallery />}
      {callEnded && (
        <Flex column fill vAlign="center" hAlign="center">
          <FlexItem>
            <Text weight="bold">{t('DISCONNECTED_FROM_MEETING')}</Text>
          </FlexItem>
        </Flex>
      )}
    </MsalAuthenticationTemplate>
  );
};

export default Home;
