import { RemoteParticipant, VideoStreamRenderer } from '@azure/communication-calling';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import logger from 'SERVICES/logger';
import utils from 'SERVICES/utils';

import { teamMeetingSelector } from 'COMPONENTS/Home/teamMeetingSlice';
import {
  IParticipantVideo,
  PARTICIPANT_EVENT_CHANGE,
  PARTICIPANT_EVENT_NAME,
  STREAM_TYPE,
} from 'CONSTANTS/appConstants';

const useVideoGallery = () => {
  const call = useSelector(teamMeetingSelector.call);
  const callState = useSelector(teamMeetingSelector.callState);
  // FIXME: Render issue similar to other component
  // eslint-disable-next-line no-unused-vars
  const [reRenderComponent, setReRenderComponent] = useState<any>(undefined);
  const remoteParticipantVideo = useRef<IParticipantVideo[]>([]);

  /**
   * This function is used to create render element for video & screenshare
   * @param {*} stream
   * @returns Render element
   */
  const getRemoveVideoStream = async (stream: any) => {
    const renderer = new VideoStreamRenderer(stream);
    // Create a renderer view for the remote video stream.
    const view = await renderer.createView();
    const remoteVideoContainer = document.createElement('div');
    remoteVideoContainer.classList.add('media-parent-container');

    // Attach the renderer view to the UI.
    remoteVideoContainer.appendChild(view.target);

    return remoteVideoContainer;
  };

  /**
   * Written a common function to update all the operation with switch case to avoid the syncronisation problem
   * though we can write it with async-await, still I prefer to use switch case to avoid code duplication
   * @param {*} participant Actual participant whose information need to be updated
   * @param {*} info Information constant which needs to be updated
   * @param {*} stream In case of screen share OR video stream we will be using this field
   */
  const udpateParticipantInformation = async (
    participant: RemoteParticipant,
    info: string,
    stream?: any
  ) => {
    const participantId = utils.getId(participant.identifier);
    const findIndex = remoteParticipantVideo.current.findIndex(
      (ele: IParticipantVideo) => ele.userId === participantId
    );

    logger.debug(
      'udpateParticipantInformation participantId:',
      participantId,
      'If exist:',
      findIndex
    );
    if (findIndex !== -1) {
      logger.debug('UPDATING PARTICIPANT INFO', info, stream, participant?.displayName);
      const member: IParticipantVideo = remoteParticipantVideo.current[findIndex];
      switch (info) {
        case PARTICIPANT_EVENT_CHANGE.nameChanged:
          member.displayName = participant.displayName;
          break;
        case PARTICIPANT_EVENT_CHANGE.muteChanged:
          member.isMuted = participant.isMuted;
          break;
        case PARTICIPANT_EVENT_CHANGE.speakingChanged:
          member.isSpeaking = participant.isSpeaking;
          break;
        case PARTICIPANT_EVENT_CHANGE.stateChanged:
          member.state = participant.state;
          break;
        case PARTICIPANT_EVENT_CHANGE.screenShareChanged:
          if (stream.isAvailable) {
            member.isScreenSharingOn = true;
            member.screenShareStream = {
              id: stream.id,
              isAvailable: true,
              renderElement: await getRemoveVideoStream(stream),
            };
            logger.debug('Screen share started');
          } else {
            member.isScreenSharingOn = false;
            member.screenShareStream = undefined;
            logger.debug('Screen share stoped');
          }
          break;
        case PARTICIPANT_EVENT_CHANGE.videoChanged:
          if (stream.isAvailable) {
            member.videoStream = {
              id: stream.id,
              isAvailable: stream.isAvailable,
              renderElement: await getRemoveVideoStream(stream),
            };
            logger.debug('Video started');
          } else {
            member.videoStream = undefined;
            logger.debug('Video stopped');
          }
          break;
        default:
          logger.debug('Invalid information');
      }
      remoteParticipantVideo.current.splice(findIndex, 1, member);
      remoteParticipantVideo.current = [...remoteParticipantVideo.current];
      setReRenderComponent(Date.now);
      return;
    }

    if (stream?.isAvailable) {
      logger.debug('Required data not found but stream is available');
    }
  };

  const checkForStream = async (stream: any, participant: RemoteParticipant) => {
    logger.debug(
      'CHECKING FOR STREAM',
      'AVAILABLE:',
      stream.isAvailable,
      'RECEIVING:',
      stream.isReceiving,
      'TYPE:',
      stream.mediaStreamType
    );
    const videoType =
      stream.mediaStreamType === STREAM_TYPE.screenSharing
        ? PARTICIPANT_EVENT_CHANGE.screenShareChanged
        : PARTICIPANT_EVENT_CHANGE.videoChanged;

    await udpateParticipantInformation(participant, videoType, stream);
  };

  const subscribeToRemoteParticipant = async (participant: RemoteParticipant) => {
    const participantId = utils.getId(participant.identifier);
    const findIndex = remoteParticipantVideo.current.findIndex(
      (ele) => ele.userId === participantId
    );

    if (findIndex === -1) {
      const participantVideo = {
        userId: utils.getId(participant.identifier),
        displayName: participant.displayName,
        isMuted: participant.isMuted,
        isSpeaking: participant.isSpeaking,
      };
      remoteParticipantVideo.current = [...remoteParticipantVideo.current, participantVideo];
    } else {
      remoteParticipantVideo.current = [...remoteParticipantVideo.current];
    }

    // AKMSTEAMS-505
    participant.on(PARTICIPANT_EVENT_NAME.displayNameChanged, async () => {
      logger.debug('displayNameChanged', participant?.displayName);
      await udpateParticipantInformation(participant, PARTICIPANT_EVENT_CHANGE.nameChanged);
    });

    participant.on(PARTICIPANT_EVENT_NAME.isSpeakingChanged, async () => {
      logger.debug('Speaking changed', participant?.displayName, participant?.isSpeaking);
      await udpateParticipantInformation(participant, PARTICIPANT_EVENT_CHANGE.speakingChanged);
    });
    participant.on(PARTICIPANT_EVENT_NAME.isMutedChanged, async () => {
      logger.debug('Mute status changed', participant?.displayName, participant?.isMuted);
      await udpateParticipantInformation(participant, PARTICIPANT_EVENT_CHANGE.muteChanged);
    });

    participant.on(PARTICIPANT_EVENT_NAME.stateChanged, async () => {
      if (participant.state === 'Connected') {
        logger.debug('Participant Connected', participant);
        await udpateParticipantInformation(participant, PARTICIPANT_EVENT_CHANGE.stateChanged);
      }
    });

    participant.on(PARTICIPANT_EVENT_NAME.videoStreamsUpdated, (e) => {
      logger.debug('videoStreamsUpdated', e, participant?.displayName);
      e.added.forEach(async (addedStream) => {
        logger.debug('VIDEO STREAM ADDED', participant?.displayName);
        // Case when someone has already shared screen or video before we logs into the call
        if (addedStream.isAvailable) {
          await checkForStream(addedStream, participant);
        }
        addedStream.on(PARTICIPANT_EVENT_NAME.isAvailableChanged, async () => {
          logger.debug(
            '[VIDEO-ISSUE]',
            'IS_AVAILABLE CHANGED:',
            addedStream?.isAvailable,
            participant?.displayName
          );

          await checkForStream(addedStream, participant);
        });
      });
    });

    participant.videoStreams?.forEach(async (stream) => {
      // This check is added as per TAP discussion
      logger.debug(
        'Adding the check as per TAP',
        'name:\n',
        participant?.displayName,
        'stream isAvailable:\n',
        stream.isAvailable
      );
      if (stream.isAvailable) {
        await checkForStream(stream, participant);
      }
      stream.on(PARTICIPANT_EVENT_NAME.isAvailableChanged, async () => {
        logger.debug('Stream Available Changed for participant', participant?.displayName);
        await checkForStream(stream, participant);
      });
    });
  };

  useEffect(() => {
    if (call) {
      call.remoteParticipants.forEach(async (rp: RemoteParticipant) => {
        logger.debug('participant already in call:', rp);
        await subscribeToRemoteParticipant(rp);
      });

      call.on(PARTICIPANT_EVENT_NAME.remoteParticipantsUpdated, (e: any) => {
        logger.debug('remoteParticipantsUpdated event:', e);
        e.added.forEach(async (p: RemoteParticipant) => {
          await subscribeToRemoteParticipant(p);
        });
        e.removed.forEach((p: RemoteParticipant) => {
          logger.debug('Participant Disconnected', p);
          const id = utils.getId(p.identifier);
          remoteParticipantVideo.current = remoteParticipantVideo.current.filter(
            (ele: IParticipantVideo) => ele.userId !== id
          );
          setReRenderComponent(Date.now);
        });
      });
    }
  }, [call]);

  return {
    callState,
    remoteParticipantVideo: remoteParticipantVideo.current,
  };
};

export default useVideoGallery;
