import {
  CallAgent,
  CallClient,
  CallCommon,
  TeamsMeetingLinkLocator,
} from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { fetchACSAccessToken } from 'API/api';
import { CALL_EVENTS } from 'CONSTANTS/appConstants';
import logger from 'SERVICES/logger';

class ACSCallClient {
  public callClient: CallClient | undefined;
  public callAgent: CallAgent | undefined;
  public tokenCredential: AzureCommunicationTokenCredential | undefined;
  public call: CallCommon | null | undefined;
  public listeners: Map<string, any> | undefined;

  async setupCallTeamsCall() {
    try {
      this.listeners = new Map();
      this.callClient = new CallClient();
      this.tokenCredential = new AzureCommunicationTokenCredential({
        refreshProactively: true,
        tokenRefresher: async () => fetchACSAccessToken(),
      });
      this.callAgent = await this.callClient.createCallAgent(this.tokenCredential, {
        displayName: undefined,
      });
      this.callAgent.on(CALL_EVENTS.CALL_UPDATED, async (eventData: any) => {
        if (eventData?.added?.length !== 0) {
          [this.call] = eventData.added;
        }
        if (eventData?.removed.length !== 0) {
          this.call = null;
        }
        this._notify(CALL_EVENTS.CALL_UPDATED, this.call);
      });
    } catch (error) {
      logger.error('Error in teams acs call setup', error);
      throw error;
    }
  }

  /**
   * This method will be used to join call
   * @param {Object} meetingId
   * @param {Object} mediaOptions { muted: boolean }
   */
  joinMeeting(meetingId: TeamsMeetingLinkLocator, mediaOptions = { muted: true }) {
    try {
      this.callAgent?.join(meetingId as TeamsMeetingLinkLocator, { audioOptions: mediaOptions });
    } catch (error) {
      logger.error('Error while joining call in acsCallClient', error);
      throw error;
    }
  }

  /**
   * This method will be used to hangup call
   */
  async hangupCall() {
    try {
      await this.call?.hangUp();
    } catch (error) {
      logger.error('Error while call hangup in acsCallClient', error);
      throw error;
    }
  }

  /**
   * This method will be used to dispose Call
   */
  async disposeCall() {
    try {
      await this.callAgent?.dispose();
      this._cleanUp();
    } catch (error) {
      logger.error('Error while disposing call in acsCallClient', error);
    }
  }

  /**
   * This method will be used to subscribe for listener
   * @param {string} eventName
   * @param {function} callback
   * @returns string {subscriberId}
   */
  subscribe(eventName: string, callback: any) {
    try {
      let listenersForEvent = this.listeners?.get(eventName);
      if (!listenersForEvent) {
        listenersForEvent = [];
      }
      // We used randomId to give unique identifier for subscriberID
      const randomId = (Math.random() + 1).toString(36).substring(2);
      listenersForEvent.push({
        id: randomId,
        callback,
      });
      this.listeners?.set(eventName, listenersForEvent);

      return randomId;
    } catch (err) {
      logger.error('Error: While subscribing event', eventName, err);
      throw err;
    }
  }

  _notify(eventName: string, eventData: any) {
    try {
      const listenersForEvent = this.listeners?.get(eventName);
      const dataToSend = eventData;
      if (listenersForEvent) {
        listenersForEvent.forEach((callbackObj: any) => {
          callbackObj.callback(dataToSend);
        });
      }
    } catch (error) {
      logger.error('Error: While executing listener', error);
      throw error;
    }
  }

  _cleanUp() {
    this.listeners?.clear();
    this.listeners = undefined;
    this.call = null;
    this.callAgent = undefined;
    this.tokenCredential = undefined;
  }
}
export default ACSCallClient;
