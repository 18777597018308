import { API, API_CONTEXT } from 'CONSTANTS/apiConstants';
import serverAPI from 'SERVICES/serverAPI';

export const getStreamerMeetingDetails = async (meetingId: string) => {
  const { data } = await serverAPI.get(`${API.meetings}/${meetingId}/${API_CONTEXT.streamer}`);
  return data;
};

export const fetchACSAccessToken = async () => {
  const { data } = await serverAPI.post(API.fetchAcsToken, {});
  return data.token;
};
