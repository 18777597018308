import axios from 'axios';
import { AXIOS_CONSTANTS } from 'CONSTANTS/apiConstants';
import { ICallBackType } from 'CONSTANTS/appConstants';
import logger from './logger';

let getAccessTokenCallback: ICallBackType;
/**
 *
 * @param {Function} callback: Callback to get access token
 */
export const injectCallbackToGetAccessToken = (callback: ICallBackType) => {
  if (typeof callback === 'function') {
    getAccessTokenCallback = callback;
  } else {
    logger.error('Please provide callback function to get accessToken');
  }
};

export const getAccessToken = () => getAccessTokenCallback();

export const createAxiosInstance = (baseURL: string) => {
  return axios.create({
    baseURL,
    headers: {
      Accept: AXIOS_CONSTANTS.REQUEST_HEADERS.CONTENT_TYPE_JSON,
      'Content-Type': AXIOS_CONSTANTS.REQUEST_HEADERS.CONTENT_TYPE_JSON,
    },
    timeout: AXIOS_CONSTANTS.REQUEST_TIMEOUT,
  });
};
