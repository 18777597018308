import { CommunicationIdentifierKind } from '@azure/communication-common';

const utils = {
  getId(identifier: CommunicationIdentifierKind | any) {
    switch (identifier.kind) {
      case 'communicationUser':
        return identifier.communicationUserId;
      case 'phoneNumber':
        return identifier.phoneNumber;
      case 'microsoftTeamsUser':
        return identifier.microsoftTeamsUserId;
      case 'unknown':
        return identifier.id;
      default:
        return identifier.id; // can be undefined
    }
  },
  getStreamId(userId: string, stream: any) {
    return `${userId}-${stream.id}-${stream.mediaStreamType}`;
  },
};

export default utils;
