import { CallState } from '@azure/communication-calling';
import { createSlice } from '@reduxjs/toolkit';
import ACSCallClient from 'SERVICES/ACSCallClient';

interface ITeamMeetingSlice {
  call: any;
  callState: CallState | undefined;
  incomingAudioElement: any;
  teamsCallClient: ACSCallClient | undefined;
}

const initialState: ITeamMeetingSlice = {
  call: undefined,
  callState: undefined,
  incomingAudioElement: null,
  teamsCallClient: undefined,
};

const teamMeetingSlice = createSlice({
  name: 'teamMeeting',
  initialState,
  reducers: {
    callAdded(state, action) {
      state.call = action.payload;
    },
    setCallState(state, action) {
      state.callState = action.payload;
    },
    setIncomingAudioElement(state, action) {
      state.incomingAudioElement = action.payload;
    },
    setTeamsCallClientObject(state, action) {
      state.teamsCallClient = action.payload;
    },
  },
});

export default teamMeetingSlice.reducer;

export const teamMeetingActions = { ...teamMeetingSlice.actions };

export const teamMeetingSelector = {
  call: (store: any) => store.teamMeeting?.call,
  callState: (store: any) => store.teamMeeting?.callState,
  incomingAudioElement: (store: any) => store.teamMeeting?.incomingAudioElement,
  teamsCallClient: (store: any) => store.teamMeeting?.teamsCallClient,
};
