export const API_URL = process.env.REACT_APP_API_URL;

export const AXIOS_CONSTANTS = {
  REQUEST_HEADERS: {
    AUTH_TOKEN_KEY: 'Authorization',
    GRAPH_TOKEN_KEY: 'Authorization',
    CONTENT_TYPE_JSON: 'application/json',
  },
  REQUEST_TIMEOUT: 30000,
};

export const STATUS_CODE = Object.freeze({
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED_USER: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  PAYLOAD_TOO_LARGE: 413,

  // App Error
  SOMETHING_WENT_WRONG: 6000,
  NETWORK_NOT_REACHABLE: 6001,
  ERROR_SETTING_UP_REQUEST: 6002,
});

export const ERROR = Object.freeze({
  TOKEN_MISSING_OR_INVALID: 1001,
  MEETING_NOT_FOUND: 1003,
  INVALID_MEETING_REQUEST: 1008,
  INVALID_MEETING_LANG_LIST: 1009,
  FEATURE_NOT_ENABLED: 1012,
  SOMETHING_WENT_WRONG: 1004,
  MEETING_NOT_ASSIGNED: 1010,
});

export const API_CONTEXT = Object.freeze({
  streamer: 'STREAMER',
});

export const API = {
  meetings: '/streamer/meeting',
  fetchAcsToken: '/streamer/identity/access-token',
};

export const STREAMER_CONST = 'STREAMER';
