import { Provider, teamsTheme } from '@fluentui/react-northstar';
import PropTypes from 'prop-types';
const style = {
  height: '100vh',
  background: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  // marginTop: '2%',
  alignItems: 'center',
};
const ThemeProvider = ({ children }: any) => {
  return (
    <Provider theme={teamsTheme} style={style}>
      {children}
    </Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
