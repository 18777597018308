import { createSlice } from '@reduxjs/toolkit';

interface IAppSlice {
  errorTimeStamp: any;
}

const initialState: IAppSlice = {
  errorTimeStamp: undefined,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setErrorTimestamp(state) {
      state.errorTimeStamp = Date.now();
    },
  },
});

export const appActions = { ...appSlice.actions };

export const appSelector = {
  errorTimestamp: (state: any) => state.app.errorTimestamp,
};
export default appSlice.reducer;
