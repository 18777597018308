import { configureStore } from '@reduxjs/toolkit';
import teamMeetingSlice from 'COMPONENTS/Home/teamMeetingSlice';
import appSlice from './appSlice';

// ConfigureStore function internally sets up the thunk middleware
const store = configureStore({
  reducer: {
    app: appSlice,
    teamMeeting: teamMeetingSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
