import { StreamMedia, VideoTile } from '@azure/communication-react';
import { Text } from '@fluentui/react-northstar';
import useVideoGallery from 'COMPONENTS/VideoGallery/useVideoGallery';
import { STREAMER_CONST } from 'CONSTANTS/apiConstants';
import { IParticipantVideo, VIDEO_GALLERY } from 'CONSTANTS/appConstants';
import { useRef, useState } from 'react';
import { useTranslation } from 'SERVICES/i18n';
import './VideoGallery.css';
const VideoGallery = () => {
  const { callState, remoteParticipantVideo } = useVideoGallery();
  const { t } = useTranslation(STREAMER_CONST, { keyPrefix: 'STREAMER' });

  // FIXME: Render issue similar to other component
  // eslint-disable-next-line
  const [renderComponent, setRenderComponent] = useState<any>(undefined);
  const activeSpeakerParticipant = useRef<IParticipantVideo | any>(undefined);
  // Added this for positioning requirement
  const isScreenSharingStarted = useRef<any>(undefined);

  // Finding out speaker who is currently speaking
  // Additional check for mute flag because when screen sharing started, isSpeaking flag always remains true
  const activeSpeaker =
    remoteParticipantVideo?.find((speaker) => speaker.isSpeaking && !speaker.isMuted) || undefined;
  // Finding out if screen is shared for any participant
  const screenSharingParticipant =
    remoteParticipantVideo?.find((speaker) => speaker?.isScreenSharingOn) || undefined;
  // As per AKMSTEAMS-536, we need to remember the active speaker even if he stops speaking
  if (
    !activeSpeakerParticipant.current ||
    (activeSpeakerParticipant.current &&
      activeSpeaker &&
      activeSpeakerParticipant.current.userId !== activeSpeaker.userId)
  ) {
    activeSpeakerParticipant.current = activeSpeaker;
  }

  // If screen sharing is stopped we need to focus on active layout
  if (!screenSharingParticipant && isScreenSharingStarted.current) {
    isScreenSharingStarted.current = undefined;
    setRenderComponent(Date.now);
  }

  // Case when screenshare started and need to focus on video layout
  if (screenSharingParticipant && !isScreenSharingStarted.current) {
    isScreenSharingStarted.current = screenSharingParticipant;
    setRenderComponent(Date.now);
  } else if (!screenSharingParticipant) {
    isScreenSharingStarted.current = undefined;
  }

  const activeSpeakerStyles = {
    root: {
      width: '20% !important',
      height: '22% !important',
      top: '78% !important',
      marginLeft: '3px !important',
    },
  };

  const render = () => {
    if (callState === VIDEO_GALLERY.connected && isScreenSharingStarted.current) {
      return (
        <>
          <VideoTile
            displayName={isScreenSharingStarted.current.displayName}
            isMuted={isScreenSharingStarted.current.isMuted}
            renderElement={
              isScreenSharingStarted.current.screenShareStream ? (
                <StreamMedia
                  videoStreamElement={
                    isScreenSharingStarted.current.screenShareStream?.renderElement
                  }
                />
              ) : null
            }
          />
          {activeSpeakerParticipant.current ? (
            <VideoTile
              showMuteIndicator={true}
              styles={activeSpeakerStyles}
              displayName={activeSpeakerParticipant.current?.displayName}
              isMuted={activeSpeakerParticipant.current.isMuted}
              isSpeaking={activeSpeakerParticipant.current.isSpeaking}
              renderElement={
                activeSpeakerParticipant.current.videoStream ? (
                  <StreamMedia
                    videoStreamElement={activeSpeakerParticipant.current.videoStream?.renderElement}
                  />
                ) : null
              }
            />
          ) : (
            <div className="no-active-speaker">
              <Text> {t('NO_ACTIVE_PARTICIPANT_SPEAKING')} </Text>
            </div>
          )}
        </>
      );
    }
    if (callState === VIDEO_GALLERY.connected && activeSpeakerParticipant.current) {
      return (
        <VideoTile
          displayName={activeSpeakerParticipant.current.displayName}
          showMuteIndicator={true}
          isMuted={activeSpeakerParticipant.current.isMuted}
          isSpeaking={activeSpeakerParticipant.current.isSpeaking}
          renderElement={
            activeSpeakerParticipant.current.videoStream ? (
              <StreamMedia
                videoStreamElement={activeSpeakerParticipant.current.videoStream?.renderElement}
              />
            ) : null
          }
        />
      );
    }
    return callState === VIDEO_GALLERY.connected ? (
      <div className="no-active-speaker">
        <Text> {t('NO_ACTIVE_PARTICIPANT_SPEAKING')} </Text>
      </div>
    ) : null;
  };

  return <div className="video-gallery">{render()}</div>;
};

export default VideoGallery;
